import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Logout from 'components/authentication/card/Logout';
import Dashboard from 'components/dashboards/default';
import AppContext from 'context/Context';
import AbandonedFormLayout from 'components/abandoned-form/FormLayout';
import { CartList } from 'components/abandoned-form/CartList';
import { Subscription } from 'components/subscription/subscription';
import { WinBackCampaign } from 'components/win-back-campaign/WinBackCampaign';
import { SettingsAccount } from 'components/settings/SettingsAccount';
import ForgetPassword from 'components/settings/ForgetPassword';
import { GDPR } from 'components/GDPRmandatory/gdpr';
import ResetPasswordForm from 'components/authentication/ResetPasswordForm';
import { Report } from 'components/orderNotification/reportList';
import { Configuration } from 'components/orderNotification/configuration';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition, logged, subscription },
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>        
        {
          logged ?
            <Route element={<MainLayout />}>
              <Route path="/*" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="abandoned/carts/edit" element={<AbandonedFormLayout />} />
              <Route path="abandoned/carts/overview" element={<CartList />} />              
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/settings" element={<SettingsAccount />} />
              <Route path="/gdpr" element={<GDPR />} />
              <Route path="/orders/report" element={<Report />} />
              <Route path="/orders/configuration" element={<Configuration />} />
              {
                (subscription && subscription !== 'Bootstrap') && (<Route path='/winback' element={<WinBackCampaign />} />)
              }
            </Route>
          :
            <>
              <Route path="/*" element={<Logout />} />
              <Route path="/login" element={<Logout />} />
              <Route path="/forgetpassword" element={<ForgetPassword />} />              
            </>          
        }
        <Route path='/resetpassword' element={<ResetPasswordForm />} />
      </Routes>
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
