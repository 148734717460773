import axiosApi from '../utils/axiosApi';

const getEmailClient = () => axiosApi.get('client/getEmailClient');

const updateEmailClient = (email) => axiosApi.post(`client/updateEmailClient/${email}`);

const updatePassword = (data) => axiosApi.post('client/updatePassword', data);

const requestResetLinkPassword = (shop) => axiosApi.get(`client/requestResetLinkPassword/${shop}`);

export {
    getEmailClient,
    updateEmailClient,
    updatePassword,
    requestResetLinkPassword,
}