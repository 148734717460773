import axiosApi from '../utils/axiosApi';

const getHooks = () => axiosApi.get(`webhooks/getHooks`);

const getActiveHooks = () => axiosApi.get(`webhooks/getActiveHooks`);

const getHook = (hookId) => axiosApi.get(`webhooks/getHook/${hookId}`);

const setStateToOrderHook = (data) => axiosApi.post('webhooks/updateStateOrderHook', data);

const updateOrderHook = (data) => axiosApi.post('webhooks/updateOrderHook', data);

export {
    getHook,
    getHooks,
    setStateToOrderHook,
    getActiveHooks,
    updateOrderHook,
}