import React, { memo, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { formatPhoneNumberIntl, isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { sendTest } from "services/appConfigService";
import { getPhones } from 'services/phonesService';

export const PhonesNumberSend = memo(() => {
    const [loadingPhones, setLoadingPhones] = useState(false);
    const [phones, setPhones] = useState([]);
    const [phoneNumberToSend, setPhoneNumberToSend] = useState('');

    useEffect(() => {
        loadPhones();
    }, []);

    const loadPhones = async () => {
        try {
            setLoadingPhones(true);
            var { data } = await getPhones();
            var phonesResult = [{}].concat(data.map(p => p));
            setPhones(phonesResult);
            setPhoneNumberToSend('');
        } catch (error) {
            toast.error(`Error loading data.`, {
                theme: 'colored'
            });
        } finally {
            setLoadingPhones(false);
        }
    }

    const loadOptionPhones = () => (
        [...phones].map((p, i) => {
            return (<option key={i} value={p.phone}>{p.phone ? formatPhoneNumberIntl('+'.concat(p.phone)) : 'choose a phone number'}</option>)
        })
    )

    const onSendTest = async () => {
        var message = document.getElementsByName('message')[0].value;
        try {
            var isValidNumber = isValidPhoneNumber('+'.concat(phoneNumberToSend));
            if(phoneNumberToSend && isValidNumber && message) {
                var phoneSelected = phones.find(p => p.phone === phoneNumberToSend);
                setLoadingPhones(true);
                await sendTest(JSON.stringify({
                    phoneNumber: phoneNumberToSend,
                    message,
                    countryISO: phoneSelected.countryISO,
                    countryCode: phoneSelected.countryCode
                }));
                toast.success(`Message sent successfully.`, {
                    theme: 'dark'
                });
            } else {
                let messageToShow = isValidNumber ? 'Please enter a valid message' : 'Please choose a phone number'
                toast.warning(messageToShow, {
                    theme: 'colored',
                    className: 'bg-warning'
                });
            }
        } catch (error) {
            var { response: { data: { errors }} } = error;
            toast.error(`Error sending message test. ${errors ?? ''}`, {
                theme: 'colored',
                className: 'bg-danger'
            });
        } finally {
            setLoadingPhones(false);
        }            
    }

    return (
        <div className="d-grid gap-3">
            {
                loadingPhones ?
                    <Spinner animation="grow" variant="primary" className="position-relative top-50 start-50 translate-middle" /> 
                :
                    (
                        <>
                            <Form.Group>
                                <Row className="g-2">
                                    <Col lg>
                                        <Form.Label controlid="timeUnit">Test numbers</Form.Label>
                                        <Col lg className='d-flex'>
                                            <Form.Select 
                                                placeholder='choose phone number' 
                                                onChange={({ target }) => setPhoneNumberToSend(target.value)} 
                                                name="phoneNumber" 
                                                size='sm'
                                                value={phoneNumberToSend}
                                            >
                                                {loadOptionPhones()}
                                            </Form.Select>                           
                                        </Col>                    
                                    </Col>
                                </Row>                                                           
                            </Form.Group>
                            <Form.Group>
                                <Row className="g-2">
                                    <Col lg className='text-center'>
                                        <Button size='sm' variant="outline-primary" style={{marginLeft: 1, paddingLeft: '5px', paddingRight: '5px'}} onClick={onSendTest}>
                                            Send
                                        </Button>
                                    </Col>
                                </Row>                                                           
                            </Form.Group>
                        </>
                    )
            }
        </div>
    )
})