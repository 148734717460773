import { CustomPopover } from 'hooks/usePopover';
import React, { useEffect, useState } from 'react'
import { Button, Card, Spinner } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { getTemplates, removeTemplates } from 'services/templatesService';
import SimpleBarReact from 'simplebar-react';

export const Templates = ({ onMessageSelected, onMessageSelectedToEdit }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [templates, setTemplates] = useState([]);

    const loadTemplates = async ()=> {
        try {
            setIsLoading(true)
            const { data } = await getTemplates();
            setTemplates(data);
        } catch (error) {
            toast.error(`Error loading data.`, {
                theme: 'colored'
            });            
        } finally {
            setIsLoading(false);
        }
    }

    const removeTemplate = async (id)=> {
        try {
            setIsLoading(true)
            await removeTemplates(id);
            await loadTemplates();
        } catch (error) {
            toast.error(`Error loading data.`, {
                theme: 'colored'
            });            
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadTemplates();
    }, []);

    const Actions = ({ template }) => (
        <div className="end-0 top-0 pe-1 mt-1 me-2 hover-actions">
            <CustomPopover body={'Edit'}>
                <Button variant="outline-dark" size="sm" className="me-1 rounded-pill" onClick={() => onMessageSelectedToEdit(template.message, template.id)}>
                    <FaEdit />
                </Button>
            </CustomPopover>
            <CustomPopover body={'Delete'}>
                <Button variant="outline-danger" size="sm" className='rounded-pill' onClick={() => removeTemplate(template.id)}>
                    <FaTrash />
                </Button>
            </CustomPopover>
        </div>
    );

    return (
        <SimpleBarReact style={{ maxHeight: 400 }}>
            {
                isLoading ?
                    <Spinner animation="grow" variant="primary" className="position-relative top-50 start-50 translate-middle mt-4" /> 
                :
                    [...templates].map((msn, i) => {
                        return (
                        <Card 
                            className="mb-2 hover-actions-trigger list-send text-send-small" 
                            body
                            key={i}
                        >
                            <p className='mb-0' style={{cursor:'pointer'}} onClick={() => onMessageSelected(msn.message)}>{msn.message}</p>
                            <div className="w-auto"><Actions template={msn} /></div>
                        </Card>
                        )
                    })
            } 
        </SimpleBarReact>
    )
}
