import SoftBadge from 'components/common/SoftBadge';
import React, { memo } from 'react';

export const LabelForm = memo(({ labels, onPushLabel }) => {
    const mapLabels = () => {
        return labels.map((lb, i) => (
            <React.Fragment key={i}>
                <SoftBadge key={i} pill bg="primary" style={{cursor:'pointer'}} onClick={() => onPushLabel(lb)}>{lb}</SoftBadge>{' '}
            </React.Fragment>                
        ))
    }

    return (mapLabels())
});
