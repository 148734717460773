const { Popover, OverlayTrigger } = require("react-bootstrap");

const popover = (body, header, styles) => (
    <Popover id="popover-basic" style={styles}>
      {header && <Popover.Header as="h3">{header}</Popover.Header>}
      <Popover.Body>
        {body}
      </Popover.Body>
    </Popover>
  );

export const CustomPopover = ({ children, body, header, placement="top", styles={}, trigger=["hover", "hover"] }) => (
    <OverlayTrigger trigger={trigger} placement={placement} overlay={popover(body, header, styles)}>
        {children}
    </OverlayTrigger>
);