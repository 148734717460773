import Flex from "components/common/Flex";
import createMarkup from "helpers/createMarkup";
import { CustomPopover } from "hooks/usePopover";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Form, ListGroup, Row, Spinner } from "react-bootstrap";
import { FaPlus, FaTrash } from "react-icons/fa";
import { MdWarning } from "react-icons/md";
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { getPhones, addPhone, removePhone } from 'services/phonesService';

export const PhonesNumber = memo(() => {
    const [loadingPhones, setLoadingPhones] = useState(true);
    const [phones, setPhones] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        loadPhones();
    }, []);

    const loadPhones = async () => {
        try {
            setLoadingPhones(true);
            var { data } = await getPhones();
            setPhones(data);
            setPhoneNumber('');
        } catch (error) {
            toast.error(`Error loading data.`, {
                theme: 'colored'
            });
        } finally {
            setLoadingPhones(false);
        }
    }

    const onClickAddPhone = async () => {
        try {
            var isValidNumber = isValidPhoneNumber(phoneNumber ?? '');
            var infoNumber = parsePhoneNumber(phoneNumber);
            if(phoneNumber && isValidNumber) {
                setLoadingPhones(true);
                var phoneToSave = phoneNumber.replace('+', '');
                await addPhone(JSON.stringify({
                    phone: phoneToSave,
                    countryISO: infoNumber.country,
                    countryCode: parseInt(infoNumber.countryCallingCode)
                }));
                toast.success(`Phone number added successfully.`, {
                    theme: 'dark'
                });
                await loadPhones();
                setLoadingPhones(false);
            } else {
                toast.warning(`Please enter a valid phone number.`, {
                    theme: 'colored'
                });
            }
        } catch (error) {
            setLoadingPhones(false);
            const { response: { data: { errors } } } = error;
            toast.error(`${errors}`, {
                theme: 'colored'
            });
        }    
    }

    const onClickRemoveNumber = async ({ phone }) => {
        try {
            var phoneToRemove = '+' + phone;
            var isValidNumber = isValidPhoneNumber(phoneToRemove ?? '');
            if(phoneToRemove && isValidNumber) {
                setLoadingPhones(true);
                await removePhone(phone);
                toast.success(`Phone number removed successfully.`, {
                    theme: 'colored',
                    className: 'bg-success'
                });
                await loadPhones();
                setLoadingPhones(false);
            } else {
                toast.warning(`Please enter a valid phone number.`, {
                    theme: 'colored',
                    className: 'bg-warning'
                });
            }
        } catch (error) {
            setLoadingPhones(false);
            const { response: { data: { errors } } } = error;
            toast.error(`${errors}`, {
                theme: 'colored',
                className: 'bg-danger'
            });
        }
    }

    const IconRemove = (props) => {
        const { phone, onClickRemove } = props;
        return (
            <>
                <p
                    className={'h5 fw-bold'}
                    dangerouslySetInnerHTML={createMarkup('Are you sure ?')}
                />
                <p
                    className={'text-send-small'}
                    dangerouslySetInnerHTML={createMarkup(`Remember phone number ${formatPhoneNumberIntl('+'.concat(phone.phone))} will be locked for 24 hours.`)}
                />
                {/* <Button variant="falcon-default" className="rounded-pill me-2 mb-1" size="sm" onClick={onToggle}>
                    No
                </Button> */}
                <Button variant="falcon-default" className="rounded-pill me-2 mb-1" size="sm" onClick={() => onClickRemove(phone)}>
                    Yes
                </Button>
            </>
        )
    };

    return (
        <div className="d-grid gap-3">
            {
                loadingPhones ?
                    <Spinner animation="grow" variant="primary" className="position-relative top-50 start-50 translate-middle" /> 
                :
                    (
                        <>
                            <Form>
                                <Form.Group className='mb-3'>
                                    <Row className="g-2">
                                        <Col lg>
                                            <Col lg className='d-flex'>
                                                <PhoneInput
                                                    placeholder="Enter phone number"
                                                    value={phoneNumber}
                                                    onChange={setPhoneNumber}
                                                    name={'phoneNumber'}
                                                    id={'phoneNumber'}
                                                    style={{width:'100%'}}
                                                />
                                                <CustomPopover body={'add phone number'}>
                                                    <Button size='sm' variant="light" style={{marginLeft: 5}} onClick={onClickAddPhone}>
                                                        <FaPlus />
                                                    </Button>
                                                </CustomPopover>                            
                                            </Col>                    
                                        </Col> 
                                    </Row>                                                           
                                </Form.Group>                  
                            </Form>
                            <ListGroup>
                                {
                                    [...phones].length === 0 ?
                                        <Flex justifyContent="between" alignItems="center">
                                            <p
                                                className={'text-send-small'}
                                                dangerouslySetInnerHTML={createMarkup('White list empty')}
                                            />
                                        </Flex>
                                    :
                                    [...phones].map((p, i) => {
                                        return (
                                            <ListGroup.Item key={i}>
                                                <Flex justifyContent="between" alignItems="center" className={'text-send-small'}>
                                                    {formatPhoneNumberIntl('+'.concat(p.phone))}
                                                    <CustomPopover 
                                                        body= {
                                                            <IconRemove phone={p} onClickRemove={onClickRemoveNumber} />
                                                        } 
                                                        header= {
                                                            <MdWarning style={{height:25, width:25, color:'var(--toastify-color-warning)'}} />
                                                        } 
                                                        placement={'right'} 
                                                        trigger='click'
                                                    >
                                                        <Button variant="outline-danger" className="rounded-pill me-2 mb-1" size="sm">
                                                            <FaTrash style={{cursor:'pointer'}}  />
                                                        </Button>
                                                    </CustomPopover>
                                                </Flex>
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                            </ListGroup>
                        </>
                    )
            }
        </div>
    )
})