import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';
import { useSearchParams } from "react-router-dom";
import { Base64 } from 'js-base64';
import AppContext from 'context/Context';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Section from 'components/common/Section';
import logo from 'assets/img/illustrations/flowstates_logo_big.png';
import { useForm } from "react-hook-form";
import { updatePassword } from 'services/clientService';
import axios from 'axios';

const eyeIconStyle = {
    top: '0.6rem',
    color: '#00284a',
    padding: 0,
    position: 'absolute',
    right: '2.2rem',
    fontSize: '1.1rem',
    cursor: 'pointer'
};

const ResetPasswordForm = ({ layout }) => {
  // State
  const [ searchParams ] = useSearchParams();
  var passwordPattern = new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$');
  const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
  } = useForm();
  
  const [formData, setData] = useState({});
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(true);
  const [result, setResult] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const params = Object.fromEntries([...searchParams]);
    const { val } = params;
    setToken(val); 
  };

  const onSubmit = async (data) => {
    setData(data);
    try {
      const instanceAxios = axios.create({
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": 'application/json',
        },
        baseURL: process.env.REACT_APP_URL_API
      });
      setLoading(true);
      await instanceAxios.post('client/resetPassword', JSON.stringify(data));
      toast.success(`Password updated successfull.`, {
          theme: 'colored'
      });
      setResult(true);
    } catch (error) {
      const { response: { data: { errors } } } = error;
      toast.error(`${errors}`, {
          theme: 'colored'
      });
      setResult(false);
    } finally {
        setLoading(false);
    }
};

  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={4} md={6} xxl={3} className="py-3 position-relative">
          <div className='d-flex flex-center fw-bolder fs-5 mb-4'>
            <img src={logo} alt="Logo" width={200} />
          </div>
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col
                  md={12}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  <div className="p-4 p-md-5 flex-grow-1">                      
                    <Flex layout="card" justifyContent="between" alignItems="center" className="mb-2">
                      <h5>{result ? 'Password updated successfully' : 'Reset password'}</h5>
                    </Flex>
                    {
                      result ? <Form.Label>Try access again from shopify web site.</Form.Label>
                      : (
                        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                          <Form.Group className="mb-3" controlId="newPassword">
                              <Form.Label>New password</Form.Label>
                              <div style={{position: 'relative'}}>
                                  <Form.Control
                                      type={isNewPassword ? "password": "text"}
                                      name="newPassword"
                                      placeholder="Enter your new password"
                                      isInvalid={!!errors.newPassword}
                                      {...register('newPassword', { required: 'New password field is required.', })}
                                      {...register('newPassword', { validate: value => passwordPattern.test(value) || 'Must be between 6 and 16 characters. Must be at least one number, and at least one special character.' })}
                                  />
                                  { isNewPassword ? <FaEye style={eyeIconStyle} onClick={() => setIsNewPassword(!isNewPassword)} /> : <FaEyeSlash style={eyeIconStyle} onClick={() => setIsNewPassword(!isNewPassword)} /> }
                                  <Form.Control.Feedback type="invalid">
                                      {errors.newPassword && errors.newPassword.message}
                                  </Form.Control.Feedback>
                              </div>
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="confirmPassword">
                              <Form.Label>Confirm Password</Form.Label>
                              <Form.Control
                                  type={isNewPassword ? "password": "text"}
                                  placeholder="Confirm password"
                                  name="confirmPassword"
                                  isInvalid={!!errors.confirmPassword}
                                  {...register('confirmPassword', {
                                      required: 'Confirm password field is required.',
                                      validate: value => value === watch('newPassword') || 'The password do not match.'
                                  })}
                              />
                              <Form.Control.Feedback type="invalid">
                                  {errors.confirmPassword && errors.confirmPassword.message}
                              </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="float-end">
                              <Button variant="primary" type="submit" size="sm">
                                  {
                                      loading ?
                                          <Spinner animation="grow" variant="light" size="sm" />
                                      :
                                          'Save'
                                  }
                              </Button>
                          </Form.Group>                        
                        </Form>
                      )
                    }
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

ResetPasswordForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

ResetPasswordForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default ResetPasswordForm;
