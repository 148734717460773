import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';
import { useContext, useEffect, useState } from 'react';
import { CardGroup } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { getMessagesConfig } from 'services/appConfigService';
import { FaEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AppContext from 'context/Context';

export const CartList = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [messagesList, setMessagesList] = useState([]);
    const navigate = useNavigate();

    const loadMessages = async () => {
        try {
            setIsLoading(true);
            var result = await getMessagesConfig(0, true);
            setMessagesList(result.data);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }        
    }

    const {
        setBreadcrumbs
    } = useContext(AppContext);

    useEffect(() => {
        setBreadcrumbs('Overview')
        loadMessages();
    }, []);

    const getNumberName = (num) => {        
        if (num === 1) {            
            return 'Initial';
        }

        if (num === 2) {
            return 'Second';
        }

        if (num === 3) {
            return 'Third';
        }
    }

    const onHandleEdit = () => {
        navigate(`/abandoned/carts/edit`, {
            replace: true
          });
    }

    return (
        <>
            {
                isLoading ?
                <div className="position-relative" style={{height: '200px'}}>
                    <Spinner animation="grow" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
                </div>
                :
                <FalconComponentCard className="h-100">
                    <FalconComponentCard.Header light={false} children={
                        <Button variant="falcon-default" className="rounded-pill me-2 float-end" size="sm" onClick={onHandleEdit}>
                            <FaEdit />
                        </Button>
                    } />
                    <FalconComponentCard.Body>
                        <CardGroup>
                            {
                                messagesList.map((msn, i) => {
                                    return (
                                        <PageHeader
                                            title={`${getNumberName(msn.number)} attempt`}
                                            description={msn.message}
                                            className={`mb-2 ${(i + 1) === messagesList.length ? ' ' : 'me-3'}`}
                                            col={{md: '12', xs: '12'}}
                                            key={i}
                                        >
                                            <div className="light p-2 position-absolute top-0 end-0">
                                                <SoftBadge bg={`${msn.enable ? 'success' : 'danger'}`} className='me-2'>
                                                    { msn.enable ? 'Enabled' : 'Disabled' }    
                                                </SoftBadge> 
                                            </div>
                                            <small className={`text-muted fst-italic ${msn.enable ? ' ' : 'text-decoration-line-through'}`}>
                                                message to be sent {msn.delay} {msn.time}
                                            </small>
                                        </PageHeader>
                                    )
                                })
                            }
                        </CardGroup>
                    </FalconComponentCard.Body>
                </FalconComponentCard>                
            }
        </>
    );
}