import PageHeader from 'components/common/PageHeader';
import { CustomPopover } from 'hooks/usePopover';
import React, { memo, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';

// services
import { getWinBackConfig } from 'services/appConfigService';

export const Overview = memo(({ loadAgain, onClickEdit, setCampaign, attempt }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [messagesList, setMessagesList] = useState([]);

    const loadMessages = async () => {
        try {
            setIsLoading(true);
            var result = await getWinBackConfig();
            setMessagesList(result.data);
            setCampaign(result.data[0].enable);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }        
    }

    useEffect(() => {
        loadMessages();
    }, [loadAgain]);

    const getNumberName = (num) => {        
        if (num === 1) {            
            return 'Initial';
        }

        if (num === 2) {
            return 'Second';
        }

        if (num === 3) {
            return 'Third';
        }
    }

    const onEventClick = (number) => {
        onClickEdit(number);        
    }

    return (
        <>
            {
                isLoading ?
                <div className="position-relative" style={{height: '200px'}}>
                    <Spinner animation="grow" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
                </div>
                :
                (
                    messagesList.map((msn, i) => {
                        return (
                            <PageHeader
                                title={`${getNumberName(msn.number)} message`}
                                description={msn.message}
                                className={`mb-3 list-send ${attempt === (i + 1) ? 'card-bold' : ''}`}
                                col={{md: '12', xs: '12'}}
                                key={i}
                                id={`pageHeader_${i + 1}`}
                            >
                                <small className={`text-muted fst-italic ${msn.enable ? ' ' : 'text-decoration-line-through'}`}>
                                    message to be sent {msn.delay} {msn.time}
                                </small>
                                <CustomPopover body={'Edit attempt'}>
                                    <Button 
                                        onClick={() => onEventClick(msn.number)} 
                                        variant="falcon-default" 
                                        className="rounded-pill me-2 mb-2 position-absolute bottom-0 end-0" 
                                        size="sm"
                                    >
                                        <FaEdit />
                                    </Button>
                                </CustomPopover>
                            </PageHeader>
                        )
                    })
                )
            }
        </>
    )
});
