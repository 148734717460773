import { settings } from 'config';
import { setItemToStore } from 'helpers/utils';

export const configReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_CONFIG':
      if (payload.setInStore) {
        setItemToStore(payload.key, payload.value);
      }
      return {
        ...state,
        [payload.key]: payload.value
      };
    case 'REFRESH':
      return {
        ...state
      };
    case 'RESET':
      localStorage.clear();
      return {
        ...state,
        ...settings
      };
    case 'login':
      const { val, token, credits, subscription } = action.payload;            
      return {
          ...state,
          logged: true,
          val,
          token,
          credits,
          subscription
      }
    case 'logout':
      return {
        ...state,
        logged: false,
      }
    case 'search':
      return {
        ...state,
        filterDash: action.payload
      }
    case 'credits':
      return {
        ...state,
        credits: action.payload
      }
    case 'set_breadcrumbs':
      return {
        ...state,
        breadcrumbs: action.payload
      }
    default:
      return state;
  }
};
