import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import SoftBadge from 'components/common/SoftBadge';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = data => ({
  tooltip: {
    trigger: 'axis',
    formatter: '<strong>{b0}</strong> : {c0}'
  },
  xAxis: {
    data: ['Week 4', 'Week 5', 'week 6', 'week 7']
  },
  series: [
    {
      type: 'line',
      data,
      smooth: true,
      lineStyle: {
        width: 3
      },

      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: {
    bottom: '2%',
    top: '2%',
    right: '10px',
    left: '10px'
  }
});

const TotalOrder = ({ data, name, total, credits, creditsTest, isLoading, isCount }) => {
  return (
    <Card className="h-md-100 animate__animated animate__fadeIn">
      <Card.Header className="pb-0 d-flex position-relative">
        <h6 className="mb-0 mt-2">Total {name}</h6>
        { isCount && (<Badge pill bg="200" className="text-primary fs--2 position-absolute end-0 me-3" style={{alignSelf: 'center'}}>
          test credits {creditsTest}
        </Badge>)}
      </Card.Header>

      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div className='d-flex w-100 position-relative'>
          <h2 className="fw-normal text-700 mb-1 lh-1">
            {
              isLoading ? 
              (
                <Spinner animation="grow" variant="primary" style={{marginRight:10}}/>
              )
              :
              (
                total               
              )
            }  
          </h2>
          { isCount && (<SoftBadge bg="success" pill className={'ms-2'} style={{alignSelf: 'center'}}>
            used credits {credits}
          </SoftBadge>)}          
        </div>
        <div className="ps-0">
          {/* <BasicECharts
            echarts={echarts}
            options={getOptions(data)}
            style={{ width: '8.5rem', height: 90 }}
          /> */}
        </div>
      </Card.Body>
    </Card>
  );
};

TotalOrder.propTypes = { data: PropTypes.array.isRequired };

export default TotalOrder;
