export const version = '4.0.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
const data = localStorage.getItem('identifier');

export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: true, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  logged: !!data,
  token: data,
  shop: localStorage.getItem('shop'),
  filterDash: {
    startDate:  null,
    endDate: null
  }
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
