import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
//import team3 from 'assets/img/icons/user-plus.svg';
//import Avatar from 'components/common/Avatar';
//import { FaEllipsisH } from 'react-icons/fa';
import AppContext from 'context/Context';

const ProfileDropdown = () => {
  const { config: { credits }, logout } = useContext(AppContext);

  const onLogout = () => {
    let val = localStorage.getItem('val');
    logout();
    window.location.replace(`/login?val=${val}`);
  }

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        className="pe-0 ps-2 nav-link"
      >
        {/* <FaEllipsisH /> */}
        {/* <Avatar src={team3} /> */}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="37"
          viewBox="0 0 16 16"
          fill="none"
        >
          <circle cx="2" cy="8" r="2" fill="#6C6E71"></circle>
          <circle cx="8" cy="8" r="2" fill="#6C6E71"></circle>
          <circle cx="14" cy="8" r="2" fill="#6C6E71"></circle>
        </svg>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item className="fw-bold text-warning">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Credit balance {credits}</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/subscription">
            Upgrade
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/settings">
            Settings
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} onClick={onLogout}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
