import axiosApi from '../utils/axiosApi';

const getSubscriptionsActive = () => axiosApi.get(`/sendmulecxSubscription/getSuscriptionsActives`);

const handleStartSubscription = (data) => axiosApi.post(`/sendmulecxSubscription/handleStartSubscription`, data);

const handleRecharge = (data) => axiosApi.post(`/sendmulecxSubscription/HandleRecharge`, data);

export {
    getSubscriptionsActive,
    handleStartSubscription,
    handleRecharge
}