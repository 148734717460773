import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap"
import { toast } from "react-toastify";
import { getEmailClient, updateEmailClient } from "services/clientService";

export const EmailForm = () => {
    var mailPattern = new RegExp('^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$'); 
    const [formData, setData] = useState({ email: '' });
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleFieldChange = e => {
        setData({
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async e => {
        const form = e.currentTarget;
        e.preventDefault();
        setValidated(false);
    
        if (form.checkValidity() === false) {
          e.stopPropagation();
          setValidated(true);
          return;
        }
    
        try {
            setLoading(true);
            await updateEmailClient(formData.email);
            toast.success(`Email updated successfull.`, {
                theme: 'colored'
            });
        } catch (error) {
            const { response: { data: { errors } } } = error;
            toast.error(`${errors}`, {
                theme: 'colored'
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setLoading(true);
            const { data } = await getEmailClient();
            setData({ email: data });
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    }

    return (
        <Form noValidate onSubmit={handleSubmit} validated={validated}>                        
            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Update Email</Form.Label>
                <Form.Control
                    required
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleFieldChange}
                    placeholder="Enter your email"
                    pattern={mailPattern.source}
                />

                <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="float-end">
                <Button variant="primary" type="submit" size="sm">
                {
                    loading ?
                        <Spinner animation="grow" variant="light" size="sm" />
                    :
                        'Save'
                }
                </Button>
            </Form.Group>
        </Form>
    )
}