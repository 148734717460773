import React, { useContext, useEffect, useState } from 'react';
import WeeklySales from './WeeklySales';
import { Row, Col } from 'react-bootstrap';
import {
  totalOrder,
  weeklySalesData,
} from 'data/dashboard/default';

import TotalOrder from './TotalOrder';
import TotalSales from './TotalSales';
import TopProducts from './TopProducts';
import { getCampaign, getConverted, getCount, getSales } from 'services/reportService';
import AppContext from 'context/Context';
import MarketShare from './MarketShare';

export const marketShare = [
  { id: 1, value: 0, name: 'Attempt 1', color: 'primary' },
  { id: 2, value: 0, name: 'Attempt 2', color: 'gray-300' },
];

const getCampaignConfig = (result) => {
  var config = result.map(at => ({
    id: at.attempt,
    value: at.count,
    name: `Attempt ${at.attempt}`,
    color: at.attempt === 1 ? 'primary' : 'gray-300'
  }))
  config.pop();
  return config;
}

const getConfigCountChart = (categories, data, name) => {
  return {
      options: {
          chart: {
              id: `basic-bar ${name}`
          },
          xaxis: {
              categories
          },
          yaxis: {
              title: {
                  text: 'count',
                  style: {
                      color: '#6F6E6E'
                  }
              }
          },
          title: {
              text: 'Sent total message per attempt.',
              floating: false,
              offsetY: 0,
              align: 'center',
              style: {
                  color: '#6F6E6E'
              }
          }
      },
      series: [
          {
              name,
              data
          }
      ],
  }
};

const getConfigSalesChart = (categories, data, name) => {
  return {
      options: {
          chart: {
              id: `basic-bar ${name}`
          },
          xaxis: {
              categories
          },
          yaxis: {
              title: {
                  text: 'sales AUD',
                  style: {
                      color: '#6F6E6E'
                  }
              }
          },
          title: {
              text: 'Sales total per attempt.',
              floating: false,
              offsetY: 0,
              align: 'center',
              style: {
                  color: '#6F6E6E'
              }
          }
      },
      series: [
          {
              name,
              data
          }
      ],
  }
};

const getConfigConvertedChart = (categories, data, name) => {
  return {
      options: {
          chart: {
              id: `basic-bar ${name}`
          },
          xaxis: {
              categories
          },
          yaxis: {
              title: {
                  text: 'converted count',
                  style: {
                      color: '#6F6E6E'
                  }
              }
          },
          title: {
              text: 'Converted sales total per attempt.',
              floating: false,
              offsetY: 0,
              align: 'center',
              style: {
                  color: '#6F6E6E'
              }
          }
      },
      series: [
          {
              name,
              data
          }
      ],
  }
};

const topProducts = [
  ['product', 'Sent', 'Converted'],
  ['attempt 1', 0, 0],
  ['attempt 2', 0, 0],
  ['attempt 3', 0, 0],
];

const totalSales = [
  [0, 0, 0],
];

const Dashboard = () => {

  const [count, setCount] = useState({total: 0, config: getConfigCountChart([''], [0])});
  const [loadCount, setLoadCount] = useState(false);

  const [sales, setSales] = useState({total: 0, current: '', config: getConfigSalesChart([''], [0]), details: totalSales});
  const [loadSale, setLoadSale] = useState(false);

  const [countConvert, setCountConvert] = useState({total: 0, config: getConfigConvertedChart([''], [0])});
  const [loadCountConvert, setLoadCountConvert] = useState(false);

  const [countCampaign, setCountCampaign] = useState({total: 0, config: marketShare});
  const [loadCountCampaign, setLoadCountCampaign] = useState(false);

  const [details, setDetails] = useState(topProducts);

  const loadCounts = async (filter) => {
      try {
          setLoadCount(true);
          
          var result = await getCount(JSON.stringify(filter));
          const { data: { total, credits, creditsTest, attempts } } = result;
          attempts.sort((a, b) => a.attempt - b.attempt);
          // let categories = attempts.map(a => {
          //     return `Attempt ${a.attempt}`;
          // });
          // let values = attempts.map(a => {
          //     return a.count;
          // });
          setCount({
              total,
              credits,
              creditsTest,
              config: attempts
          });
      } catch (error) {
          setLoadCount(false);            
      } finally {
          setLoadCount(false);
      }
  }

  const loadSales = async (filter) => {
      try {
          setLoadSale(true);
          
          var result = await getSales(JSON.stringify(filter));
          const { data: { total, current, attempts } } = result;
          attempts.sort((a, b) => a.attempt - b.attempt);
          let categories = attempts.map(a => {
              return `Attempt ${a.attempt}`;
          });
          let values = attempts.map(a => {
              return a.sale;
          });
          let valueSales = totalSales;

          if (attempts.length === 0) {
            valueSales = [
              [0, 0, 0],
            ];
          } else {
            valueSales = [
              [attempts[0].sale, attempts[1].sale, attempts[2].sale],
            ];
          }

          setSales({
              total,
              current,
              config: getConfigSalesChart(categories, values, 'Sales.'),
              details: valueSales
          });
      } catch (error) {
          setLoadSale(false);            
      } finally {
          setLoadSale(false);
      }
  }

  const loadConverted = async (filter) => {
      try {
          setLoadCountConvert(true);
          
          var result = await getConverted(JSON.stringify(filter));
          const { data: { total, attempts } } = result;
          attempts.sort((a, b) => a.attempt - b.attempt);         

          let categories = attempts.map(a => {
              return `Attempt ${a.attempt}`;
          });
          let values = attempts.map(a => {
              return a.count;
          });
          setCountConvert({
              total,
              config: getConfigConvertedChart(categories, values, 'Converted sales.')
          });
      } catch (error) {
          setLoadCountConvert(false);            
      } finally {
          setLoadCountConvert(false);
      }
  }

  const loadCampaign = async (filter) => {
    try {
        setLoadCountCampaign(true);
        
        var result = await getCampaign(JSON.stringify(filter));
        const { data: { total, attempts } } = result;
        const configCampaign = getCampaignConfig(attempts);
        setCountCampaign({
            total,
            config: configCampaign
        });
    } catch (error) {
        setLoadCountCampaign(false);            
    } finally {
      setLoadCountCampaign(false);
    }
}

  const getCounts = async (filter) => {
    var resultCount = await getCount(JSON.stringify(filter));
    const { data: { attempts } } = resultCount;
    attempts.sort((a, b) => a.attempt - b.attempt);
    if (attempts.length === 0) {
      return [{
        count: 0
      },
      {
        count: 0
      },
      {
        count: 0
      }]
    }
    return attempts;
  }

  const getConverts = async (filter) => {
    var result = await getConverted(JSON.stringify(filter));
      const { data: { attempts } } = result;
      attempts.sort((a, b) => a.attempt - b.attempt);
      if (attempts.length === 0) {
        return [{
          count: 0
        },
        {
          count: 0
        },
        {
          count: 0
        }]
      }
    return attempts;
  }

  const processGraphic = async (filter) => {
    try {
      const dataCount = await getCounts(filter);
      const dataConvert = await getConverts(filter);      

      const dataPartial = [
        ['product', 'Sent', 'Converted'],
        ['attempt 1', dataCount[0].count, dataConvert[0].count],
        ['attempt 2', dataCount[1].count, dataConvert[1].count],
        ['attempt 3', dataCount[2].count, dataConvert[2].count],
      ];

      setDetails(dataPartial);
  } catch (error) {       
  } finally {
  }
  }

  const {
    config: { filterDash, subscription },
    setBreadcrumbs
  } = useContext(AppContext);

  useEffect(() => {
    setBreadcrumbs('Dashboard');
    Promise.all([loadCounts(filterDash), loadSales(filterDash), loadConverted(filterDash), processGraphic(filterDash), loadCampaign(filterDash)]).then(() => {
    });
  }, [filterDash]);

  const space = subscription === 'Bootstrap' ? 4 : 3;

  return (
    <>
      <hr className='mt-0'/>

      <Row className="g-3 mb-3">
        <Col md={space} xxl={space}>
          <WeeklySales data={weeklySalesData} total={sales.total} isLoading={loadSale} />
        </Col>
        <Col md={space} xxl={space}>
          <TotalOrder data={totalOrder} name='Message sent' total={count.total} credits={count.credits} creditsTest={count.creditsTest} isLoading={loadCount} isCount={true} />
        </Col>
        <Col md={space} xxl={space}>
          <TotalOrder data={totalOrder} name='Converted sales' total={countConvert.total} isLoading={loadCountConvert} />
        </Col>
        {
          (subscription && subscription !== 'Bootstrap') && (
            <Col md={space} xxl={space}>
              <MarketShare data={countCampaign.config} radius={['100%', '87%']} totalCount={countCampaign.total} isLoading={loadCountCampaign} />
            </Col>
          )
        }
      </Row>

      <Row className="g-3">
        <Col sm={6} xxl={6}>
          <TotalSales data={sales.details} />
        </Col>
        <Col sm={6} xxl={6}>
          <TopProducts data={details} className="h-100" />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
