import FalconComponentCard from "components/common/FalconComponentCard";
import PageHeader from "components/common/PageHeader";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getActiveHooks } from "services/webHookService";
import SimpleBarReact from 'simplebar-react';

export const Overview = ({ loadingAgain }) => {
    const [hooksActive, setHooksActive] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadMessagesTemplates = async () => {
        try {
            setIsLoading(true);
            var { data } = await getActiveHooks();
            setHooksActive(data);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadMessagesTemplates();
    }, [loadingAgain]);

    return (
        <>
            {
                isLoading ?
                    <div className="position-relative" style={{height: '150px'}}>
                        <Spinner animation="grow" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
                    </div>
                :
                (
                    <FalconComponentCard className={'animate__animated animate__backInLeft'}>
                        <FalconComponentCard.Header title="Overview" light={false}  />
                        <FalconComponentCard.Body>
                            <SimpleBarReact style={{ maxHeight: 400 }}>
                                {
                                    [...hooksActive].map(h => (     
                                        <PageHeader
                                            title={`${h.hookName}`}
                                            description={h.message}
                                            className={`mb-2 list-send`}
                                            col={{md: '12', xs: '12'}}
                                            key={h.id}
                                            id={`pageHeader_${h.id}`}
                                        />                           
                                    ))
                                }
                            </SimpleBarReact>
                        </FalconComponentCard.Body>
                    </FalconComponentCard>
                )
            }
        </>
    )
}