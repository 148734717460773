import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Button, Col, Form, } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import moment from 'moment';

const TopNavRightSideNavItem = () => {
  const { search, config: { filterDash } } = useContext(AppContext);

  const onSearch = () => {
    var startDate = null;
    var endDate = null;
    var quarterAdjustment= (moment().month() % 3) + 1;

    switch (document.getElementById('period').value) {
        case '1':
            startDate = moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss');                
            break;
        case '2':
            startDate = moment().startOf('week').add(-7, 'd').format('yyyy-MM-DDTHH:mm:ss');
            endDate = moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss');
            break;
        case '3':
            startDate = moment().startOf('month').format('yyyy-MM-DDTHH:mm:ss');
            break;
        case '4':
            startDate = moment().startOf('month').add(-1, 'months').format('yyyy-MM-DDTHH:mm:ss');
            endDate = moment().startOf('month').add(-1, 'seconds').format('yyyy-MM-DDTHH:mm:ss');
            break;
        case '5':
            startDate = moment().startOf('quarter').format('yyyy-MM-DDTHH:mm:ss');
            break;
        case '6':
            endDate = moment().subtract({ months: quarterAdjustment }).endOf('month').add(1, 'months');
            startDate = endDate.clone().subtract({ months: 3 }).startOf('month');
            endDate = endDate.format('yyyy-MM-DDTHH:mm:ss');
            startDate = startDate.format('yyyy-MM-DDTHH:mm:ss');
            break;
        case '7':
            startDate = moment().startOf('year').format('yyyy-MM-DDTHH:mm:ss');
            break;
        case '8':
            startDate = moment().startOf('year').add(-1, 'years').format('yyyy-MM-DDTHH:mm:ss');
            endDate = moment().startOf('year').add(-1, 'seconds').format('yyyy-MM-DDTHH:mm:ss');
            break;
        default:
            break;
    }
    var filter = {
        startDate,
        endDate,
        period: document.getElementById('period').value
    };
    search(filter);
  }

  return (
    <Col xs="auto" className='d-flex'>
        <Form.Select defaultValue={filterDash.period} id='period' name='period' size='sm'>
            <option value={1}>This week</option>
            <option value={2}>Last week</option>
            <option value={3}>This month</option>
            <option value={4}>Last month</option>
            <option value={5}>This quarter</option>
            <option value={6}>Last quarter</option>
            <option value={7}>This year</option>
            <option value={8}>Last year</option>
        </Form.Select>
        <Button size='sm' variant="light" style={{marginLeft: 5}} onClick={onSearch}>
            <FaSearch />
        </Button>
    </Col>
  );
};

export default TopNavRightSideNavItem;
