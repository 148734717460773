import axiosApi from '../utils/axiosApi';

const getLogin = (data) => axiosApi.post(`/sendmulecx/login`, data);

const getSignUp = (data) => axiosApi.post(`/sendmulecx/signup`, data);

const getTokenActive = () => axiosApi.get(`/sendmulecx/getTokenActive`);

export {
    getLogin,
    getSignUp,
    getTokenActive,
}