import Flex from "components/common/Flex";
import AppContext from "context/Context";
import { CustomPopover } from "hooks/usePopover";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Spinner, Table } from "react-bootstrap";
import { FaDownload, FaRedoAlt } from "react-icons/fa";
import { getgdprlist, getgdprfile } from "services/reportService";
const { v4: uuidv4 } = require('uuid');

export const GDPR = () => {
    const { setBreadcrumbs } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);


    useEffect(() => {
        setBreadcrumbs('General Data Protection Regulation');
        loadData();
    }, []);

    const loadData = async () => {
        try {
            setIsLoading(true);
            var { data } = await getgdprlist();
            setData(data);
        } catch (error) {
            toast.error(`Error loading data.`, {
                theme: 'colored'
            });
        } finally {
            setIsLoading(false);
        }
    }

    const onDownload = async (id) => {
        try {
            var { data } = await getgdprfile(id);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            const fileName = uuidv4();
            link.setAttribute('download', `${fileName}.zip`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            toast.error(`Error downloading file.`, {
                theme: 'colored'
            });
        }
    }

    return (
        <>
            <Card style={{maxWidth: '55rem'}}>
                <Card.Body>
                    {
                        isLoading ?
                        <div className="position-relative">
                            <Spinner animation="grow" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
                        </div>
                        :
                        (
                            [...data].length > 0 ?
                                <Table size="sm" striped hover responsive style={{fontSize: '.875rem'}} className='animate__animated animate__fadeIn'>
                                    <thead>
                                        <tr>
                                        <th scope="col">Shop</th>
                                        <th scope="col">Topic</th>
                                        <th scope="col">Request Date</th>
                                        <th scope="col">Status</th>
                                        <th className="text-end" scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            [...data].map((r, i) => (
                                                <tr key={i}>
                                                    <td>{r.shop}</td>
                                                    <td>{r.topic}</td>
                                                    <td>{r.date}</td>
                                                    <td>{r.status}</td>
                                                    <td className="text-end">
                                                        <CustomPopover body={'Download'}>
                                                            <Button size='sm' variant="light" onClick={() => onDownload(r.id)} disabled={r.status !== 'Ready'}>
                                                                <FaDownload />
                                                            </Button>
                                                        </CustomPopover>                                
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            :
                            <Flex>
                                <p className="h6 mb-0">No requested data.</p>                                
                                <CustomPopover body={'Reload'} placement='right'>
                                    <div className="position-absolute p-3 top-0 end-0">                                    
                                        <FaRedoAlt style={{cursor: 'pointer'}} className={'ms-3'} onClick={() => loadData()} />  
                                    </div>                                               
                                </CustomPopover>                                  
                            </Flex>                             
                        )
                    }
                </Card.Body>
            </Card>
        </>
    )
}