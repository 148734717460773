import axiosApi from '../utils/axiosApi';

const getTemplates = (type = 'carts') => axiosApi.get(`/messagingDefault/getTemplates/${type}`);

const addTemplates = (data) => axiosApi.post(`/messagingDefault/addTemplate`, data);

const removeTemplates = (id) => axiosApi.get(`/messagingDefault/removeTemplate/${id}`);

export {
    getTemplates,
    addTemplates,
    removeTemplates,
}