import PageHeader from 'components/common/PageHeader';
import { CustomPopover } from 'hooks/usePopover';
import React, { memo, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';

// services
import { getMessagesConfig } from 'services/appConfigService';

export const Overview = memo(({ loadAgain, onClickEdit, attempt }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [messagesList, setMessagesList] = useState([]);

    const loadMessages = async () => {
        try {
            setIsLoading(true);
            var result = await getMessagesConfig(0, true);
            setMessagesList(result.data);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }        
    }

    useEffect(() => {
        loadMessages();
    }, [loadAgain]);

    const onCheck = async ({ target }, number) => {
        try {
            setIsLoading(true);
            var result = await getMessagesConfig(number, target.checked);
            setMessagesList(result.data);
            setIsLoading(false);
            toast.success(`Data saving success.`, {
                theme: 'dark'
            });
        } catch (error) {
            setIsLoading(false);
            toast.error(`Error saving data.`, {
                theme: 'colored'
            });
        }        
    }

    const isDisable = (num) => {        
        if (num === 1) {            
            return false;
        }

        if (num === 2) {
            let msn = [...messagesList].find(m => m.number === 1);
            return !msn.enable;
        }

        if (num === 3) {
            let msn = [...messagesList].find(m => m.number === 2);
            return !msn.enable;
        }
    }

    const getNumberName = (num) => {        
        if (num === 1) {            
            return 'Initial';
        }

        if (num === 2) {
            return 'Second';
        }

        if (num === 3) {
            return 'Third';
        }
    }

    const onEventClick = (number) => {
        onClickEdit(number);        
    }

    return (
        <>
            {
                isLoading ?
                <div className="position-relative" style={{height: '200px'}}>
                    <Spinner animation="grow" variant="primary" className="position-absolute top-50 start-50 translate-middle" />
                </div>
                :
                (
                    messagesList.map((msn, i) => {
                        return (
                            <PageHeader
                                title={`${getNumberName(msn.number)} attempt`}
                                description={msn.message}
                                className={`mb-3 list-send ${attempt === (i + 1) ? 'card-bold' : ''}`}
                                col={{md: '12', xs: '12'}}
                                key={i}
                                id={`pageHeader_${i + 1}`}
                            >
                                <div className="light p-2 position-absolute top-0 end-0">
                                    <CustomPopover body={`${msn.enable ? 'Inactive' : 'Active'} attempt`}>
                                        <Form.Check 
                                            type='switch'
                                            id={`${i} checkedSwitch`}
                                            label=''
                                            defaultChecked={msn.enable}
                                            onClick={e => onCheck(e, msn.number)}
                                            disabled={isDisable(msn.number)}
                                        />
                                    </CustomPopover>
                                </div>
                                <small className={`text-muted fst-italic ${msn.enable ? ' ' : 'text-decoration-line-through'}`}>
                                    message to be sent {msn.delay} {msn.time}
                                </small>
                                <CustomPopover body={'Edit attempt'}>
                                    <Button onClick={() => onEventClick(msn.number)} 
                                            variant="falcon-default" 
                                            className="rounded-pill me-2 mb-2 position-absolute bottom-0 end-0" 
                                            size="sm"
                                            disabled={!msn.enable}>
                                        <FaEdit />
                                    </Button>
                                </CustomPopover>
                            </PageHeader>
                        )
                    })
                )
            }
        </>
    )
});
