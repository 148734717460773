import AppContext from "context/Context";
import { CustomPopover } from "hooks/usePopover"
import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Nav, Row, Spinner, Tab } from "react-bootstrap"
import { getHook, getHooks, setStateToOrderHook } from "services/webHookService";
import { ConfigurationForm } from "./configurationForm";
import { OrdersList } from "./ordersList";
import { Overview } from "./overview";

export const Configuration = () => {
    const { setBreadcrumbs } = useContext(AppContext);        

    useEffect(() => {
        setBreadcrumbs('');
        loadHooks();
    }, []);

    const [valuesConfig, setValuesConfig] = useState({ message: '', orderId: '', orderName: '', fields: [''] });
    const { orderId, orderName, message, fields } = valuesConfig;
    const [hooks, setHooks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hookSelected, setHookSelected] = useState('');
    const [isLoadingMessage, setIsLoadingMessage] = useState(false);

    const loadHooks = async () => {
        try {
            setIsLoading(true);
            var result = await getHooks();
            var firstId = result.data[0].id;
            setValuesConfig({ ...valuesConfig, orderId: firstId });
            setHooks(result.data);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }        
    }

    const onChangeEvent = async (h) => {
        try {
            setIsLoadingMessage(true);
            var { data } = await getHook(h.id);
            setValuesConfig({ ...valuesConfig, orderId: h.id, orderName: h.hookName, message: data.message, fields: data.fields });
            setHookSelected(`link${h.id}`);
        } catch (error) {
        } finally {
            setIsLoadingMessage(false);
        }
    }

    const onChangeEnable = async (enable, id) => {
        try {
            setIsLoading(true);
            await setStateToOrderHook(JSON.stringify({ enable, hookId: id}));
        } catch (error) {
        } finally {
            await loadHooks();
        }
    }

    const onCancel = () => {
        setValuesConfig({ message: '', orderId: '', orderName: '' });
        setHookSelected('');
    };

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey={`link${orderId}`}>         
        <Row>
            <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                    {
                        isLoading ?
                            (
                                <Spinner animation="grow" variant="primary" style={{ alignSelf: 'center', marginRight:10, verticalAlign:'middle', height:'1.82rem', width:'1.82rem'}} /> 
                            ) 
                        :            
                            (
                                <OrdersList 
                                    hooks={hooks} 
                                    onChangeEvent={onChangeEvent} 
                                    onChangeEnable={onChangeEnable} 
                                    activeKey={hookSelected} 
                                />
                            )
                    }
                </Nav>
            </Col>
            <Col sm={[...hooks].some(p => p.enable) ? 5 : 9} className={`animate__animated ${[...hooks].some(p => p.enable) ? 'animate__backInLeft' : 'animate__fadeIn'}`}>
                <ConfigurationForm 
                    hookId={orderId} 
                    hookName={orderName} 
                    messageInitial={message}
                    fieldsInitial={fields}
                    loadHooks={loadHooks} 
                    setIsLoading={setIsLoadingMessage} 
                    isLoadingInitial={isLoadingMessage}
                    onCancel={onCancel}
                />
            </Col>
            {
                [...hooks].some(p => p.enable) && (
                    <Col sm={4}>
                        <Overview loadingAgain={isLoading} />
                    </Col>
                )
            }            
        </Row>
        </Tab.Container>
    )
}