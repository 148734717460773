import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { handleRecharge, handleStartSubscription } from 'services/subscriptionService';
import { toast } from 'react-toastify';

const PricingDefaultCard = ({
  pricing: {
    title,
    subTitle,
    price,
    url,
    buttonText,
    isFeatured,
    featureTitle,
    features,
    isDisable
  },
  onReloadSubscription,
  type = 'subscription'
}) => {

  const onPushSubscription = async (planName) => {
    try {
      var response = await handleStartSubscription(JSON.stringify({planName}));
      const { data: { url_confirmation }} = response;

      var anchor = document.getElementById('link_shopi');
      anchor.setAttribute('href', url_confirmation);
      anchor.click();      
      anchor.setAttribute('href', '');
      toast.success(`Please approve subscription.`, {
        theme: 'dark'
      });
      setTimeout(() => onReloadSubscription(), 3000);
    } catch (error) {
      var { response: { data: { errors }} } = error;
      toast.error(`Error purchasing subscription. ${errors ?? ''}`, {
        theme: 'colored',
        className: 'bg-danger'
      });
    }
  }

  const purchaseRecharge = async (recharge) => {
    try {
      var response = await handleRecharge(JSON.stringify({planName: recharge}));
      const { data: { url_confirmation }} = response;

      var anchor = document.getElementById('link_shopi');
      anchor.setAttribute('href', url_confirmation);
      anchor.click();      
      anchor.setAttribute('href', '');
      toast.success(`Please approve recharge.`, {
        theme: 'dark'
      });
    } catch (error) {
      var { response: { data: { errors }} } = error;
      toast.error(`Error recharging plan. ${errors ?? ''}`, {
        theme: 'colored',
        className: 'bg-danger'
      });
    }
  }

  return (
    <Card className={`cardsendmule animate__animated ${type === 'subscription' ? 'animate__fadeInLeft': 'animate__fadeInRight'}`} style={{margin: '15px'}}>
      <div className="h100">
        <div className="text-center p-4">
          <h3 className="fw-normal my-0">{title}</h3>
          <p className="mt-3">{subTitle}</p>
          <h2 className="fw-medium my-4">
            <sup className="fw-normal fs-2 me-1">$</sup>
            {price}
            {type === 'subscription' && <small className="fs--1 text-700">/ month</small>}
          </h2>
          <Button
            variant={isFeatured ? 'info' : 'light'}
            onClick={() => type === 'subscription' ? onPushSubscription(title) : purchaseRecharge(title)}
            disabled={isDisable}
            style={{color: 'royalblue'}}
          >
            {buttonText}
          </Button>
        </div>
        <hr className="border-bottom-0 m-0" />
        <div className="text-start px-sm-4 py-4">
          {/* <h5 className="fw-medium fs-0">{featureTitle}</h5> */}
          <ul className="list-unstyled mt-3">
            {features.map(feature => (
              <li className="py-1" key={feature.id}>
                <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                {feature.title}{' '}
                {feature.tag && (
                  <SoftBadge pill bg={feature.tag.type}>
                    {feature.tag.label}
                  </SoftBadge>
                )}
              </li>
            ))}
          </ul>
          { type === 'subscription' && (<Link to="https://www.flowstates.net/pricing" className="btn btn-link" target={'_blank'}>
            More about {title}
          </Link>)}
          <Link hidden={true} id='link_shopi' target={'_blank'}>
          </Link>
        </div>
      </div>
    </Card>
  );
};

PricingDefaultCard.propTypes = {
  pricing: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string,
    buttonText: PropTypes.string,
    isFeatured: PropTypes.bool,
    featureTitle: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        tag: PropTypes.shape({
          label: PropTypes.string,
          type: PropTypes.string
        })
      })
    )
  }),
  onReloadSubscription: PropTypes.func
};

export default PricingDefaultCard;
