import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';
import { useSearchParams } from "react-router-dom";
import { Base64 } from 'js-base64';
import AppContext from 'context/Context';
import { FaEye, FaEyeSlash, FaShopify } from 'react-icons/fa';

const eyeIconStyle = {
  top: '0.6rem',
  color: '#00284a',
  padding: 0,
  position: 'absolute',
  right: '2.2rem',
  fontSize: '1.1rem',
  cursor: 'pointer'
};

const LoginForm = ({ layout }) => {
  // State
  const [ searchParams ] = useSearchParams();
  const [userData, setUserData] = useState({shop: '', email: '', password: '', isEmailCheck: false});  
  const { login, signup } = useContext(AppContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isPassword, setIsPassword] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const params = Object.fromEntries([...searchParams]);
    const { val } = params;
    

    try {
      const data = Base64.decode(val);
      const { shop, email } = JSON.parse(data);
      localStorage.setItem("temp", email);
      localStorage.setItem("shop", shop);
      setUserData({shop, email, password: userData.password, isEmailCheck: !!email});
    } catch (error) {
    }    
  };

  // Handler
  const handleSubmit = async e => {
    const form = e.currentTarget;
    e.preventDefault();
    setValidated(false);

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      setLoading(true);
      if (userData.isEmailCheck) {
        await login(userData.shop, userData.password);
      } else {
        await signup(userData.shop, userData.email, userData.password);
      }
      toast.success(`Logged in as ${userData.shop}`, {
        theme: 'colored'
      });
      navigate('/dashboard', {
        replace: true
      });
    } catch (error) {
      const { response: { data: { errors } } } = error;
      toast.error(`${errors || ''}`, {
          theme: 'colored'
      });
      localStorage.removeItem("temp");
      localStorage.removeItem("shop");
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = e => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Flex layout="card" justifyContent="between" alignItems="center" className="mb-2">
        <h5>{userData.isEmailCheck ? 'Log in': 'Sign up'}</h5>
        {!userData.isEmailCheck && <Button size='sm' variant='light' onClick={() => navigate(-1)}>Go back to <FaShopify color='#bbe5b3' /></Button>}
      </Flex>
      <Form onSubmit={handleSubmit} noValidate validated={validated}>
        <Form.Group className="mb-3">
          <Form.Label>Shop</Form.Label>
          <Form.Control
            placeholder='Shop'
            value={userData.shop}
            name="shop"
            readOnly
            disabled
          />
        </Form.Group>

        {
          !userData.isEmailCheck && (
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder={'Email'}
                value={userData.email}
                name="email"
                onChange={handleFieldChange}
                required
                type='email'
              />
              <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
            </Form.Group>
          )
        }

        <Form.Group className="mb-3" >
          <Form.Label>Password</Form.Label>
          <div style={{position: 'relative'}}>
            <Form.Control
              placeholder={'Password'}
              value={userData.password}
              name="password"
              onChange={handleFieldChange}
              type={isPassword ? "password": "text"}
              required
              pattern='^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$'
            />
            { isPassword ? <FaEye style={eyeIconStyle} onClick={() => setIsPassword(!isPassword)} /> : <FaEyeSlash style={eyeIconStyle} onClick={() => setIsPassword(!isPassword)} /> }            
            <Form.Control.Feedback type="invalid">Please provide a valid password.</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Must be between 6 and 16 characters.</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">Must be at least one number, and at least one special character.</Form.Control.Feedback>
          </div>
        </Form.Group>

        {/* { userData.isEmailCheck && (<Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Link
              className="fs--1 mb-0"
              // to={`/authentication/${layout}/forgot-password`}
              to={`/forgetpassword`}
            >
              Forgot Password?
            </Link>
          </Col>
        </Row>) } */}

        <Form.Group>
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!userData.email || !userData.password}
          >
            {
              loading ?
                <Spinner animation="grow" variant="light" size="sm" />
              :
              (userData.isEmailCheck ? 'Log in': 'Sign up')
            }
          </Button>
        </Form.Group>

        {/* <Divider className="mt-4">or log in with</Divider>

        <SocialAuthButtons /> */}
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
