import React, { useEffect, useState } from 'react';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import logo from 'assets/img/illustrations/flowstates_logo_big.png';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { requestResetLinkPassword } from 'services/clientService';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [shop, setShop] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const emailToSent = localStorage.getItem('temp');
    const shopToSent = localStorage.getItem('shop');
    setEmail(emailToSent);
    setShop(shopToSent);
  });

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();

    try {
      setLoading(true);
      await requestResetLinkPassword(shop);
      toast.success(`An email is sent to ${email} with password reset link`, {
        theme: 'colored'
      });
    } catch (error) {
      const { response: { data: { errors } } } = error;
      toast.error(`Error reset password. ${errors || ''}`, {
          theme: 'colored'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form className="mt-1" onSubmit={handleSubmit}>
      <Form.Label>{shop}</Form.Label>      
      <div className="mb-3 d-grid">
        <small>We'll send you a reset link to </small>
        <Form.Label>{email}</Form.Label>
      </div>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          {
            loading ?
              <Spinner animation="grow" variant="light" size="sm" />
            :
            'Send reset link'
          }
        </Button>
        <Button className="w-100 mt-2" variant='light' onClick={() => navigate(-1)}>
          Go back Login
        </Button>
      </Form.Group>

      {/* <Link className="fs--1 text-600" to="#!">
        I can't recover my account using this page
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link> */}
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

const ForgetPassword = () => {
  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={4} md={6} xxl={3} className="py-3 position-relative">
          <div className='d-flex flex-center fw-bolder fs-5 mb-4'>
            <img src={logo} alt="Logo" width={200} />
          </div>
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col
                  md={12}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  <div className="p-4 p-md-5 flex-grow-1">                      
                    <div className="text-center">
                      <h5 className="mb-0"> Forgot your password?</h5>
                      
                      <ForgetPasswordForm />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default ForgetPassword;