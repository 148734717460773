import React from 'react';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';
import Section from 'components/common/Section';
import { Card, Col, Row } from 'react-bootstrap';
import logo from 'assets/img/illustrations/flowstates_logo_big.png';

const Logout = () => {
  return (
    <>
      <Section fluid className="py-0">
        <Row className="g-0 min-vh-100 flex-center">
          <Col lg={4} md={6} xxl={3} className="py-3 position-relative">
            <div className='d-flex flex-center fw-bolder fs-5 mb-4'>
              <img src={logo} alt="Logo" width={200} />
            </div>
            <Card className="overflow-hidden z-index-1">
              <Card.Body className="p-0">
                <Row className="h-100 g-0">
                  <Col
                    md={12}
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <div className="p-4 p-md-5 flex-grow-1">                      
                      <LoginForm />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Section>   
    </>
  );
};

export default Logout;
