import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';
import { getLogin, getSignUp } from './services/authService';
import { Base64 } from 'js-base64';
import { Chart as ChartJS, registerables } from 'chart.js';
import moment from 'moment';
ChartJS.register(...registerables);

const Main = props => {
  const data = localStorage.getItem('identifier');
  const value = localStorage.getItem('val');
  let creditsAble = 0;
  let subscriptionActive = '';

  if (value) {
    try {
      var dataDecode = Base64.decode(value);
      const { credits, subscription } = JSON.parse(dataDecode);
      creditsAble = credits;
      subscriptionActive = subscription;
    } catch (error) {
    }
  }

  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false,
    logged: !!data,
    token: data,
    val: localStorage.getItem('val'),
    filterDash: {
      startDate:  moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss'),
      endDate: null,
      period: 1
    },
    breadcrumbs: 'Dashboard',
    credits: creditsAble,
    subscription: subscriptionActive
  };

  const [config, configDispatch] = useReducer(configReducer, configState);
  
  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const login = async (shop, password) => {
    let resultLogin = await getLogin({
      shop,
      password
    });
    const { val, access_token } = resultLogin.data;
    const data = Base64.decode(val);
    const { credits, subscription } = JSON.parse(data);
    const action = {
        type: 'login',
        payload: {
            val,
            credits,
            subscription,
            token: access_token
        }
    }

    localStorage.setItem('identifier', access_token);
    localStorage.setItem('val', val);
    configDispatch(action);
  }

  const signup = async (shop, email, password) => {
    let resultSignup = await getSignUp({
      shop,
      email,
      password
    });
    const { val, access_token } = resultSignup.data;
    const data = Base64.decode(val);
    const { credits, subscription } = JSON.parse(data);
    const action = {
        type: 'login',
        payload: {
            val,
            credits,
            subscription,
            token: access_token
        }
    }

    localStorage.setItem('identifier', access_token);
    localStorage.setItem('val', val);
    configDispatch(action);
  }

  const logout = () => {
    localStorage.removeItem('identifier');
    localStorage.removeItem('val');
    const action = {
        type: 'logout'
    }
    configDispatch(action);
  }

  const search = (filter) => {
    const action = {
      type: 'search',
      payload: filter
    };
    configDispatch(action);
  }

  const updateCredits = (credits) => {
    const action = {
      type: 'credits',
      payload: credits
    };
    configDispatch(action);
  }

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle',
          'logged',
          'token',
          'val',
          'app',
        ].includes(key)
      }
    });
  };

  const setBreadcrumbs = (route) => {
    configDispatch({
      type: 'set_breadcrumbs',
      payload: route
    })
  }

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig, configDispatch, login, signup, logout, search, setBreadcrumbs, updateCredits }}>
      {props.children}
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
