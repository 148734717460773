import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { updatePassword } from "services/clientService";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const eyeIconStyle = {
    top: '0.6rem',
    color: '#00284a',
    padding: 0,
    position: 'absolute',
    right: '2.2rem',
    fontSize: '1.1rem',
    cursor: 'pointer'
};

export const PasswordForm = () => {
    var passwordPattern = new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$');
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();
    
    const [formData, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isPassword, setIsPassword] = useState(true);
    const [isNewPassword, setIsNewPassword] = useState(true);

    const onSubmit = async (data) => {
        setData(data);
    
        try {
            setLoading(true);
            await updatePassword(JSON.stringify(data));
            toast.success(`Password updated successfull.`, {
                theme: 'colored'
            });
        } catch (error) {
            const { response: { data: { errors } } } = error;
            toast.error(`${errors}`, {
                theme: 'colored'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="currentPassword">
                <Form.Label>Update password</Form.Label>
                <div style={{position: 'relative'}}>
                    <Form.Control
                        type={isPassword ? "password": "text"}
                        name="currentPassword"
                        placeholder="Enter your current password"
                        isInvalid={!!errors.currentPassword}
                        {...register('currentPassword', {
                            required: 'Current password field is required.'
                        })}
                    />
                    { isPassword ? <FaEye style={eyeIconStyle} onClick={() => setIsPassword(!isPassword)} /> : <FaEyeSlash style={eyeIconStyle} onClick={() => setIsPassword(!isPassword)} /> }            
                    <Form.Control.Feedback type="invalid">
                        {errors.currentPassword && errors.currentPassword.message}
                    </Form.Control.Feedback>
                </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="newPassword">
                <Form.Label>New password</Form.Label>
                <div style={{position: 'relative'}}>
                    <Form.Control
                        type={isNewPassword ? "password": "text"}
                        name="newPassword"
                        placeholder="Enter your new password"
                        isInvalid={!!errors.newPassword}
                        {...register('newPassword', { required: 'New password field is required.', })}
                        {...register('newPassword', { validate: value => passwordPattern.test(value) || 'Must be between 6 and 16 characters. Must be at least one number, and at least one special character.' })}
                    />
                    { isNewPassword ? <FaEye style={eyeIconStyle} onClick={() => setIsNewPassword(!isNewPassword)} /> : <FaEyeSlash style={eyeIconStyle} onClick={() => setIsNewPassword(!isNewPassword)} /> }
                    <Form.Control.Feedback type="invalid">
                        {errors.newPassword && errors.newPassword.message}
                    </Form.Control.Feedback>
                </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                    type={isNewPassword ? "password": "text"}
                    placeholder="Confirm password"
                    name="confirmPassword"
                    isInvalid={!!errors.confirmPassword}
                    {...register('confirmPassword', {
                        required: 'Confirm password field is required.',
                        validate: value => value === watch('newPassword') || 'The password do not match.'
                    })}
                />
                <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword && errors.confirmPassword.message}
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="float-end">
                <Button variant="primary" type="submit" size="sm">
                    {
                        loading ?
                            <Spinner animation="grow" variant="light" size="sm" />
                        :
                            'Save'
                    }
                </Button>
            </Form.Group>                        
        </Form>
    )
}