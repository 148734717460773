import { CustomPopover } from "hooks/usePopover"
import { useEffect } from "react"
import { Form, Nav } from "react-bootstrap"

export const OrdersList = ({ hooks, onChangeEvent, onChangeEnable, activeKey = '' }) => {

    useEffect(() => {
    }, [activeKey]);

    return (        
        [...hooks].map(h => (                            
            <Nav.Item className="position-relative card mb-2" key={`item${h.id}`}>
                <Nav.Link eventKey={`link${h.id}`} onClick={() => onChangeEvent(h)} active={activeKey === `link${h.id}`}>
                    <CustomPopover body={`${h.description}`}>
                        <h6 className="mb-0">{h.hookName}</h6>
                    </CustomPopover>
                </Nav.Link>
                <div className="light p-1 position-absolute top-0 end-0">
                    <CustomPopover body={`${h.enable ? 'Inactive' : 'Active'} Webhook`}>
                        <Form.Check 
                            type='switch'
                            id={`checkedCharSwitch${h.id}`}
                            label=''
                            defaultChecked={h.enable}
                            onClick={({ target }) => onChangeEnable(target.checked, h.id)}
                        />
                    </CustomPopover>
                </div>
            </Nav.Item>                            
        ))
    )
}