import axiosApi from '../utils/axiosApi';

const getCount = (filter) => axiosApi.post(`/reports/getcount`, filter);

const getSales = (filter) => axiosApi.post(`/reports/getsales`, filter);

const getConverted = (filter) => axiosApi.post(`/reports/getconverted`, filter);

const getCampaign = (filter) => axiosApi.post(`/reports/getcampaign`, filter);

const getgdprlist = () => axiosApi.get('/reports/getgdprlist');

const getgdprfile = (id) => axiosApi.get(`/reports/${id}/GetGdprFile`, { responseType: 'blob' })

export {
    getCount,
    getSales,
    getConverted,
    getCampaign,
    getgdprlist,
    getgdprfile,
}