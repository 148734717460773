import axios from 'axios'
import { getToken } from '../helpers/utils';

const instanceAxios = axios.create({
    headers: {
        Authorization: `Bearer ${getToken()}`
        //Authorization: `Bearer ${token}`
    },
    baseURL: process.env.REACT_APP_URL_API
})

instanceAxios.interceptors.request.use(
    config => {
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
            //config.headers['Authorization'] = `Bearer ${token}`;
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

instanceAxios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            let val = localStorage.getItem('val');          
            localStorage.removeItem('identifier');
            window.location.replace(`/login?val=${val}`);
        }
        return Promise.reject(error);
    },
)

export default instanceAxios