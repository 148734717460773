import AppContext from "context/Context";
import { useContext, useEffect, useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { EmailForm } from "./ChangeEmailForm";
import { PasswordForm } from "./ChangePasswordForm";

export const SettingsAccount = () => {
    const { setBreadcrumbs } = useContext(AppContext);
    const [key, setKey] = useState('email');

    useEffect(() => {
        setBreadcrumbs('Settings');
    }, []);

    return (
        <>
            <Card.Body style={{maxWidth: '25rem'}}>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                >
                    <Tab eventKey="email" title="Email" className='pt-3'>
                        
                    <Card style={{maxWidth: '25rem'}}>
                        <Card.Body>
                            <EmailForm />
                        </Card.Body>
                    </Card>

                    </Tab>

                    <Tab eventKey="password" title="Password" className='pt-3'>
                        
                        <Card style={{maxWidth: '25rem'}}>
                            <Card.Body>
                                <PasswordForm />
                            </Card.Body>
                        </Card>

                    </Tab>
                </Tabs>
            </Card.Body>
        </>
    )
}