import { useState } from "react"
import { Modal } from "react-bootstrap";

export const useModal = (openInitial = false) => {
  const [open, setOpen] = useState(openInitial);
  const toggle = () => setOpen(!open);

  const CenteredModal = ({ children, footer, title, size = "lg", ...rest }) => (
    <Modal
      {...rest}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={open}
      onHide={() => setOpen(false)}
    >
      <Modal.Header closeButton style={{padding: '0.6rem 0.6rem'}}>
        <Modal.Title id="contained-modal-title-vcenter" className="h5 mt-2">
          {
            title
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          children
        }
      </Modal.Body>
      {
        footer && (
          <Modal.Footer>
            {
              footer
            }
          </Modal.Footer>
        )
      }
    </Modal>
  );

  return [
    toggle,
    CenteredModal
  ]
}