import axiosApi from '../utils/axiosApi';

const addPhone = (data) => axiosApi.post(`/phonesNumber/addPhone`, data);

const removePhone = (phone) => axiosApi.get(`/phonesNumber/removePhone/${phone}`);

const getPhones = () => axiosApi.get(`/phonesNumber/getPhones`);

export {
    addPhone,
    removePhone,
    getPhones
}