import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import CountUp from 'react-countup';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaRedoAlt } from 'react-icons/fa';
import { CustomPopover } from 'hooks/usePopover';

export const SubscriptionHeader = ({ currentSubscription: { subscriptionName, amount, isActive, state, pending, billingDate, packages }, getSubscription, type, onChangeTarget }) => {

  return (
    <>
      <Card className="mb-3 animate__animated animate__fadeIn px-3">
        <Card.Body>
          <Row className='justify-content-md-center'>
            <Col md style={{textAlign: 'center'}}>
            <Flex style={{placeContent: 'center'}}>
            <Card.Title as='h5'>
              {subscriptionName}                    
            </Card.Title>
            {
              state && (
                <SoftBadge bg={`${isActive ? 'success' : 'danger'}`} className="mb-2 ms-2" pill>
                  {state}
                </SoftBadge>
              )
            }
            <CustomPopover body={'Reload'} placement='right'>
              <div>
                <FaRedoAlt style={{cursor: 'pointer'}} className={'ms-3'} onClick={getSubscription} />
              </div>              
            </CustomPopover>                  
          </Flex>
            
          <Card.Text>
            Current subscription
          </Card.Text>
          
          <Flex style={{alignItems: 'baseline', placeContent: 'center'}}>
            <h5 className="fs-0 fw-semi-bold mb-2 text-900 hover-primary">
              <CountUp end={amount} duration={2} />
            </h5>
            <h6 className="mb-0 text-600 ms-2">Credits available</h6>
          </Flex>

          <Flex style={{alignItems: 'baseline', placeContent: 'center'}}>
            <h5 className="fs-0 fw-semi-bold mb-2 text-900 hover-primary">
              {billingDate ?? '-'}
            </h5>
            <h6 className="mb-0 text-600 ms-2">Next billing date</h6>
          </Flex>
          
          {
            pending && (
              <Flex>
                <Link size='sm' variant='link' style={{color: 'royalblue'}} to={pending.url_Confirmation} target={'_blank'}>
                  {`Approve ${pending.name} subscription`}
                </Link>
                <SoftBadge bg={`danger`} className="mb-2 ms-1" pill>
                  {'pending'}
                </SoftBadge>
              </Flex>                  
            )
          } 
          {
            (state === 'active') && (
              <Button variant={`${type === 'subscription' ? 'primary' : 'light'}`} size='sm' className={`mt-2`} onClick={() => onChangeTarget()}>
                {`${type === 'subscription' ? 'Recharge' : 'Cancel'}`}
              </Button>
            )
          }
            </Col>          
          </Row>
        </Card.Body>       
      </Card>
      {/* <Collapse in={open} className={'mb-3'}>
        <div className="border rounded" style={{width: 'fit-content'}}>
          <Flex>
            <Recharge packages={packages} />
          </Flex>
        </div>
      </Collapse> */}
    </>
  );
};
