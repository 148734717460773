import AppContext from "context/Context";
import { useContext } from "react";
import { FaBullhorn } from "react-icons/fa";

export const winbackRoutes = () => {
    const {
        config: { subscription },
      } = useContext(AppContext);
    
    return (
        (subscription && subscription !== 'Bootstrap') && (
            {
                label: 'WinBackCampaign',
                labelDisable: true,
                active: true,
                children: [
                    {
                        name: 'Win-Back Campaign',
                        iconAux: <FaBullhorn />,
                        active: true,
                        to: '/winback'
                    }
                ]
            }
        )
    )
};

export default [
    winbackRoutes
];