import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppContext from "context/Context";
import { useContext, useEffect } from "react";

const columns = [
    {
        accessor: 'event',
        Header: 'Event'
    },
    {
      accessor: 'destination',
      Header: 'Phone number'
    },    
    {
      accessor: 'date',
      Header: 'Date'
    },
    {
      accessor: 'message',
      Header: 'Message'
    },
  ];
  
  const data = [
    {
        event: 'created',
        destination: '+61 424 641 720',
        date: '18/06/2023 21:45:23',
        message: 'Unchecked runtime.lastError: A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received'
    },
    {
        event: 'cancelled',
        destination: '+61 424 641 720',
        date: '18/06/2023 21:45:23',
        message: 'Unchecked runtime.lastError: A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received'
    },
    {
        event: 'paid',
        destination: '+61 424 641 720',
        date: '18/06/2023 21:45:23',
        message: 'Unchecked runtime.lastError: A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received'
    },
    {
        event: 'paid',
        destination: '+61 424 641 720',
        date: '18/06/2023 21:45:23',
        message: 'Unchecked runtime.lastError: A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received'
    },
    {
        event: 'cancelled',
        destination: '+61 424 641 720',
        date: '18/06/2023 21:45:23',
        message: 'Unchecked runtime.lastError: A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received'
    },
    {
        event: 'created',
        destination: '+61 424 641 720',
        date: '18/06/2023 21:45:23',
        message: 'Unchecked runtime.lastError: A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received'
    },
    {
        event: 'created',
        destination: '+61 424 641 720',
        date: '18/06/2023 21:45:23',
        message: 'Unchecked runtime.lastError: A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received'
    },
  ];
  
export const Report = () => {
  
    const {
        setBreadcrumbs
    } = useContext(AppContext);        

    useEffect(() => {
        setBreadcrumbs('');
      }, []);

    return(
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable
        pagination
        perPage={5}
      >
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTablePagination
            table
          />
        </div>
      </AdvanceTableWrapper>
    )
}