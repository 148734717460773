import axiosApi from '../utils/axiosApi';

const getMessagesConfig = (number, check) => axiosApi.get(`/appConfig/getMessages/${number}/${check}`);

const getAppConfig = (attemptId) => axiosApi.get(`/appConfig/${attemptId}`);

const getWinBackAttemptConfig = (attemptId) => axiosApi.get(`/appConfig/getWinBackConfig/${attemptId}`);

const addAppConfig = (appConfig) => axiosApi.post('/appConfig/addAppConfig', appConfig);

const addWinBackConfig = (winBackConfig) => axiosApi.post('/appConfig/addWinBackConfig', winBackConfig);

const sendTest = (data) => axiosApi.post('/appConfig/sendTest', data);

const getWinBackConfig = () => axiosApi.get('appConfig/getWinBackMessages');

const checkCampaign = (check) => axiosApi.get(`appConfig/checkCampaign/${check}`);

export {
    getMessagesConfig,
    getAppConfig,
    addAppConfig,
    addWinBackConfig,
    sendTest,
    getWinBackConfig,
    getWinBackAttemptConfig,
    checkCampaign
}